import { DateTime } from "luxon";
import {
  AppointmentDetailsResponse,
  AppointmentDetailsResponseArticle,
  GetAppointmentDetailsResponse,
} from "types/Appointment";
import { APPOINTMENT_STATUS } from "types/common";
import { Resource } from "types/Resource";

export type FormArticleSlot = {
  id?: number;
  serviceVariantId: number | null;
  isStaffSelectedByClient?: boolean;

  staffId: number | null;
  resourceId: number | null;
  isResourceRequired: boolean;

  startTime: DateTime;
  endTime: DateTime;
};

export type FormArticle = {
  // TODO: Make exclusive whenever not stressed or for future devs after quiting
  serviceVariantId: Nullable<number>;
  packageVariantId: Nullable<number>;

  promotion: Nullable<number>;
  flexService: Nullable<number>;

  subscriptionRedemptionId?: Nullable<number>;
  rewardLoyaltyCard?: Nullable<number>;
  // ODOT

  price: number;

  finalPrice: number;

  slots: FormArticleSlot[];
};

export type FormClient = {
  id: number | null;
  isNewClient: boolean;

  firstName: string;
  lastName?: string;
  phoneNumber: string;
  phoneCountryCode: string;
};

export type AppointmentFormValues = {
  client: FormClient;
  articles: FormArticle[];
  notes: string;
  // TODO: Fix this type to AppointmentStatusOptions
  status: APPOINTMENT_STATUS;
  createdOnline: boolean;
  appointmentId: number | null;

  // home service organization specific fields:
  area: Nullable<number>;
  address: Nullable<number>;
};

type CreateNewArticleArgs = {
  startTime?: DateTime;
  endTime?: DateTime;
  staffId?: number;
  resourceId?: number;
  client?: FormClient;
};

export function createNewFormArticle(args: CreateNewArticleArgs = {}): FormArticle {
  const { startTime, endTime, staffId, resourceId } = args;

  return {
    serviceVariantId: null,
    packageVariantId: null,

    price: 0,
    finalPrice: 0,

    promotion: null,
    flexService: null,

    slots: [
      {
        isStaffSelectedByClient: false,
        serviceVariantId: null,
        staffId: staffId || null,
        resourceId: resourceId || null,
        startTime: startTime || DateTime.now(),
        endTime: endTime || startTime || DateTime.now(),
        isResourceRequired: Boolean(resourceId),
      },
    ],
  };
}

export const DEFAULT_APPOINTMENT_FORM_CLIENT = {
  isNewClient: false,
  id: null,

  firstName: "",
  lastName: "",
  phoneNumber: "",
  phoneCountryCode: "+965",
};

export function createAppointmentFormDefaultValues({
  client,
  ...customFirstArticle
}: CreateNewArticleArgs = {}): AppointmentFormValues {
  return {
    client: client ? client : DEFAULT_APPOINTMENT_FORM_CLIENT,
    articles: [createNewFormArticle(customFirstArticle)],
    notes: "",
    createdOnline: false,
    status: APPOINTMENT_STATUS.NEW,
    appointmentId: null,
    area: null,
    address: null,
  };
}

function translateArticleSlotToFormArticleSlot(
  slot: AppointmentDetailsResponseArticle["slots"][0],
  activeResources?: Resource[]
): FormArticleSlot {
  const { serviceVariant, staff, resource, start, end, isStaffSelectedByClient } = slot;

  return {
    id: slot.id,
    isStaffSelectedByClient,
    serviceVariantId: serviceVariant,
    staffId: staff,
    resourceId: !!activeResources?.find((activeResource) => activeResource.id === resource)
      ? resource
      : null,
    isResourceRequired: Boolean(resource),
    startTime: DateTime.fromISO(start),
    endTime: DateTime.fromISO(end),
  };
}

function translateArticleToFormArticle(
  article: AppointmentDetailsResponseArticle,
  activeResources?: Resource[]
): FormArticle {
  const {
    slots,
    serviceVariant,
    packageVariant,
    finalPrice,
    markedPrice,
    subscriptionRedemption,
    promotion,
    rewardLoyaltyCard,
    flexService,
  } = article;

  return {
    serviceVariantId: serviceVariant,
    packageVariantId: packageVariant,
    flexService,
    promotion,
    subscriptionRedemptionId: subscriptionRedemption ? subscriptionRedemption.id : null,
    rewardLoyaltyCard,

    price: parseFloat(markedPrice || "0"),
    finalPrice: parseFloat(finalPrice) || 0,

    slots: slots.map((slot) => translateArticleSlotToFormArticleSlot(slot, activeResources)),
  };
}

export function translateAppointmentToFormAppointment(
  appointment: AppointmentDetailsResponse,
  activeResources?: Resource[]
): AppointmentFormValues {
  const { client, notes, status, articles, createdOnline, address, area } = appointment;

  return {
    client: { ...DEFAULT_APPOINTMENT_FORM_CLIENT, id: client || null },
    articles: articles.map((article) => translateArticleToFormArticle(article, activeResources)),
    notes: notes ?? "",
    createdOnline,
    appointmentId: appointment.id,
    address,
    area,
    // @ts-expect-error fix this type later
    status,
  };
}

export function compileAppointment(
  appointment: GetAppointmentDetailsResponse["appointment"],
  articles: GetAppointmentDetailsResponse["articles"],
  slots: GetAppointmentDetailsResponse["slots"]
) {
  const compiledArticles = articles.map((article) => ({
    ...article,
    slots: slots.filter((slot) => slot.article === article.id),
  }));

  return {
    ...appointment,
    articles: compiledArticles,
  };
}
