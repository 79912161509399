import { Box, Button } from "@mui/material";
import useCheckoutModal from "hooks/useCheckoutModal";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectAllPermissions } from "store/myPermissions/myPermissionsSlice";
import { CheckoutModalView } from "store/slices/checkout/checkoutSlice";
import { InvoiceStatus } from "types/Checkout";

import { useCheckoutFormContext } from "../CheckoutFormContext";
import CheckoutRefundAdditionalPaymentsModal from "../CheckoutRefundAdditionalPaymentsModal";
import { getCheckoutStatus } from "../utils";

const AddPaymentMethodsFooter = () => {
  const { t } = useTranslation(["checkout", "invoices"]);
  const [isRefundAdditionalPaymentsModalOpen, setIsRefundAdditionalPaymentsModalOpen] =
    useState(false);

  const { hasUnpaid } = useSelector(selectAllPermissions);
  const { setCheckoutModalView } = useCheckoutModal();

  const { deposit, onlinePayment, balance, totalDue, watch, isRefundInvoice } =
    useCheckoutFormContext();

  // The difference between refund and refundInvoice is:
  // refund alone is money refund for the client, that has paid extra amount upfront
  // refundInvoice is the invoice that needs to be refunded, refunding purchased items or services
  const { existingClient, newClient, refund, refundInvoice } = watch();
  const hasClient = existingClient || newClient;

  const hasSaveAsUnpaidAccess = hasUnpaid.editAccess;

  const hasUnpaidAmount = balance > 0;

  const hasPaidExtraAmountThatNeedsRefunding = balance < 0;

  const hasAlreadyProcessedRefund = refund?.amount === balance;

  const { articlesToRefund = [] } = refundInvoice || {};

  // cannot partially pay if no client
  const canConfirmSale =
    (hasClient && hasSaveAsUnpaidAccess) ||
    (hasClient && !hasUnpaidAmount) ||
    (!hasClient && !hasUnpaidAmount);

  const status = getCheckoutStatus(totalDue, balance);

  const buttonVariant = status === InvoiceStatus.Paid ? "contained" : "outlined";

  const buttonColor = status === InvoiceStatus.Paid ? "primary" : "error";

  const switchToConfirmSaleSection = () => {
    if (hasPaidExtraAmountThatNeedsRefunding && !hasAlreadyProcessedRefund) {
      setIsRefundAdditionalPaymentsModalOpen(true);
      return;
    }

    setCheckoutModalView(
      isRefundInvoice ? CheckoutModalView.ConfirmRefund : CheckoutModalView.ConfirmSale
    );
  };

  const closeRefundAdditionalPaymentsModal = (moveForward?: boolean) => {
    setIsRefundAdditionalPaymentsModalOpen(false);
    if (moveForward) {
      setCheckoutModalView(CheckoutModalView.ConfirmSale);
    }
  };

  const hasDeposit = deposit ? deposit.amount > 0 : false;
  const hasOnlinePayment = onlinePayment > 0;

  const canProcessRefund = isRefundInvoice && balance === 0 && articlesToRefund.length > 0;

  return (
    <Box width="100%" boxShadow="topDivider" p={3}>
      <Button
        fullWidth
        variant={buttonVariant}
        color={buttonColor}
        onClick={switchToConfirmSaleSection}
        disabled={isRefundInvoice ? !canProcessRefund : !canConfirmSale}
      >
        {t(getButtonLabel(status))}
      </Button>

      {hasPaidExtraAmountThatNeedsRefunding && (
        <CheckoutRefundAdditionalPaymentsModal
          isModalOpen={isRefundAdditionalPaymentsModalOpen}
          onCloseModal={closeRefundAdditionalPaymentsModal}
          refundAmount={balance}
          hasDeposit={hasDeposit}
          hasOnlinePayment={hasOnlinePayment}
        />
      )}
    </Box>
  );
};

export default AddPaymentMethodsFooter;

const getButtonLabel = (status: InvoiceStatus) => {
  switch (status) {
    case InvoiceStatus.Paid:
      return "next";

    case InvoiceStatus.Partially:
      return "saveRemaining";

    default:
      return "saveUnpaid";
  }
};
