import { Pencil, Remove } from "@bookpeep/ui";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { DiscountCodeChip } from "components/common/Chips";
import OnlineBookingChip from "components/common/Chips/OnlineBookingChip";
import { ACTIVE_INVOICE_STATUSES } from "constants/invoice";
import useAppointmentDrawer from "hooks/useAppointmentDrawer";
import useAppointmentEditAccess from "hooks/useAppointmentEditAccess";
import { DateTime } from "luxon";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "store";
import { selectCheckoutAppointmentById } from "store/appointments/appointmentsSelectors";
import { updateAppointment } from "store/appointments/appointmentsSlice";
import { getAllAppointmentsOverview } from "store/appointmentsOverview/appointmentsOverviewSlice";
import { selectOnlinePaymentsByAppointmentId } from "store/onlinePayments/onlinePaymentsSlice";
import { selectDepositsByAppointmentId } from "store/selectors";
import { AppointmentDrawerMode } from "store/slices/appointmentDrawer/appointmentDrawerSlice";
import { DepositRemovalOptions } from "store/slices/deposits/depositsApi";
import { trackEvent } from "tracking";
import { AppointmentStatusOptions } from "types/Appointment";
import { PeepPayLinkStatus } from "types/Deposit";
import useNotify from "utils/notifications/useNotify";

import DepositSection from "../DepositSection";
import HandleRefundModal from "../HandleRefundModal";
import OnlineBookingPaymentChip from "../OnlineBookingPaymentChip";
import AppointmentActionChip from "./AppointmentActionChip";
import AppointmentStatus from "./AppointmentStatus";
import ConfirmCancelAppointmentModal from "./ConfirmCancelAppointmentModal";
import { UpdateAppointmentFunction } from "./utils";
import ViewInvoiceButton from "./ViewInvoiceButton";

type AppointmentDetailsToolbarProps = {
  appointmentId: number;
  shouldShowEditButton?: boolean;

  handleChangeStatus?: (status: AppointmentStatusOptions) => void;
  handleAfterStatusUpdate?: () => void;

  shouldShowAllSelectableStatuses?: boolean; // used for edit appointment form mode
};

const AppointmentDetailsToolbar = ({
  appointmentId,
  shouldShowEditButton = true,
  handleChangeStatus,
  handleAfterStatusUpdate,
  shouldShowAllSelectableStatuses = false,
}: AppointmentDetailsToolbarProps) => {
  const { t } = useTranslation(["translation", "appointments"]);
  const dispatch = useDispatch();
  const notify = useNotify();

  const { outletId, openAppointmentDrawerEditMode, appointmentDrawerView } = useAppointmentDrawer();

  const appointment = useSelector(selectCheckoutAppointmentById(appointmentId));

  const { canEditAppointment, isAppointmentEditable, canEditAppointmentStatus } =
    useAppointmentEditAccess({
      appointmentId: appointment?.id,
    });

  const [localAppointmentStatus, setLocalAppointmentStatus] = useState(
    appointment?.status || AppointmentStatusOptions.New
  );
  const [isUpdateRequestLoading, setIsUpdateRequestLoading] = useState(false);
  const [isConfirmCancelModalOpen, setIsConfirmCancelModalOpen] = useState(false);
  const [isHandleRefundModalOpen, setIsHandleRefundModalOpen] = useState(false);

  const onlinePayments = useSelector(selectOnlinePaymentsByAppointmentId(appointment?.id));
 
  const onlinePaymentsTotal = onlinePayments
    .filter((onlinePayment) => onlinePayment.status === "PAID")
    .reduce((onlinePaidTotal, onlinePayment) => onlinePaidTotal + Number(onlinePayment.total), 0);

  const nonCouponOnlinePaymentsTotal = onlinePayments
  .filter((onlinePayment) => onlinePayment.status === "PAID" && onlinePayment.paymentType !== 'coupon' )
  .reduce((onlinePaidTotal, onlinePayment) => onlinePaidTotal + Number(onlinePayment.total), 0);

  const deposit = useSelector(selectDepositsByAppointmentId(appointment?.id || 0));

  if (!appointment) return null;

  const handleUpdateAppointment: UpdateAppointmentFunction = ({
    newStatus,
    refundOption = null,
    notes = appointment.notes,
  }) => {
    setLocalAppointmentStatus(newStatus);

    if (
      shouldShowRefundModal &&
      !isHandleRefundModalOpen &&
      (newStatus === AppointmentStatusOptions.NoShow ||
        newStatus === AppointmentStatusOptions.Canceled)
    ) {
      setIsHandleRefundModalOpen(true);
    } else {
      setIsUpdateRequestLoading(true);
      dispatch(
        // @ts-expect-error
        updateAppointment({
          id: appointment.id,
          data: {
            shouldUpdateClient: false, // we don't want to update the client data, because note passing a client object will remove the client from the appointment and set it to walk-in unless we pass shouldUpdateClient as false it will ignore updating the client
            status: newStatus,
            notes,

            refundOption,
          },
        })
      )
        .unwrap()
        .then(() => {
          // @ts-expect-error
          if (outletId !== null) dispatch(getAllAppointmentsOverview(outletId));

          if (newStatus === AppointmentStatusOptions.Canceled)
            trackEvent("Appointment Cancelled", { "Appointment Id": appointment.id });
          else if (newStatus !== appointment.status)
            trackEvent("Appointment Status Changed", {
              "Appointment Id": appointment.id,
              "Appointment Date": appointment.date,
              Trigger: "Appointment View",
            });
          notify(t("appointments:successfullyUpdatedAppointment"), "success");

          handleAfterStatusUpdate && handleAfterStatusUpdate();
        })
        .catch((error) => {
          setLocalAppointmentStatus(appointment?.status);
          notify(`${t("appointments:failedToUpdateAppointment")}. ${error?.detail ?? ""}`, "error");
        });
      setIsUpdateRequestLoading(false);
    }
  };

  const onCloseRefundModal = () => {
    setIsHandleRefundModalOpen(false);
  };

  const hasUnpaidPeepPayLink =
    deposit &&
    deposit?.peepPay &&
    (deposit?.peepPay?.status === PeepPayLinkStatus.Pending ||
      deposit?.peepPay?.status === PeepPayLinkStatus.Expired ||
      deposit?.peepPay?.status === PeepPayLinkStatus.Canceled);

  const shouldShowRefundModal =
    (deposit && deposit.amount > 0 && !hasUnpaidPeepPayLink) || nonCouponOnlinePaymentsTotal > 0;

  const updateAppointmentWithRefundHandling = (refundOption: DepositRemovalOptions) => {
    handleUpdateAppointment({
      newStatus: localAppointmentStatus,
      refundOption,
    });

    setIsHandleRefundModalOpen(false);
  };

  const handleUpdateStatus = (newStatus: AppointmentStatusOptions) => {
    setLocalAppointmentStatus(newStatus);

    // in edit appointment form mode we don't want to update the status immediately, we want to update it when the user clicks on the save button, expect for the NoShow status
    if (handleChangeStatus) {
      handleChangeStatus(newStatus);
    } else {
      handleUpdateAppointment({ newStatus });
    }
  };

  const handleCancelAppointment = (cancellationReason: string) => {
    const notes = `${
      appointment.notes ? appointment.notes : ""
    } \nCancellation Reason: ${cancellationReason}`;

    handleUpdateAppointment({ newStatus: AppointmentStatusOptions.Canceled, notes });
  };

  const handleEditAppointment = () => {
    openAppointmentDrawerEditMode({
      appointmentId,
      outletId: appointment.outlet,
      clientId: appointment?.client,
    });
  };

  const hasActiveInvoice = appointment.invoices.some((invoice) =>
    ACTIVE_INVOICE_STATUSES.includes(invoice.status)
  );

  const isEditAppointmentMode = appointmentDrawerView === AppointmentDrawerMode.EditAppointment;
  const shouldShowDownPaymentAndDepositSection = isAppointmentEditable;

  return (
    <>
      <ConfirmCancelAppointmentModal
        isOpen={isConfirmCancelModalOpen}
        onClose={() => setIsConfirmCancelModalOpen(false)}
        handleCancelAppointment={handleCancelAppointment}
        isLoading={isUpdateRequestLoading}
      />

      <HandleRefundModal
        isHandleRefundModalOpen={isHandleRefundModalOpen}
        onClose={onCloseRefundModal}
        amount={deposit && deposit.amount > 0 ? deposit.amount : nonCouponOnlinePaymentsTotal}
        updateAppointmentWithRefundHandling={updateAppointmentWithRefundHandling}
      />

      <Stack gap={1.5} width="100%">
        <Grid container spacing={1} mt={0} alignItems="center" justifyContent="space-between">
          <Grid item xs={12} sm={4}>
            <AppointmentStatus
              appointmentStatus={localAppointmentStatus}
              shouldDisableStatusSelect={!canEditAppointmentStatus}
              handleChangeStatus={handleUpdateStatus}
              shouldShowAllSelectableStatuses={shouldShowAllSelectableStatuses}
            />
          </Grid>

          {isAppointmentEditable && canEditAppointment && (
            <>
              {shouldShowEditButton && (
                <Grid item xs={12} sm={4}>
                  <AppointmentActionChip
                    clickable
                    onClick={handleEditAppointment}
                    iconColor="#4048D6"
                    icon={<Pencil fontSize="medium" />}
                    label={t("edit")}
                    disabled={!canEditAppointment}
                    fullWidth
                  />
                </Grid>
              )}

              <Grid item xs={12} sm={4}>
                <AppointmentActionChip
                  clickable
                  onClick={() => {
                    setIsConfirmCancelModalOpen(true);
                  }}
                  iconColor="red"
                  icon={<Remove fontSize="small" />}
                  label={t("cancel")}
                  disabled={!canEditAppointmentStatus}
                  fullWidth
                />
              </Grid>
            </>
          )}

          {hasActiveInvoice && (
            <Grid
              item
              // NOTE: Do not add breakpoints here, it will break the layout
              justifySelf="flex-end"
            >
              <ViewInvoiceButton appointmentId={appointmentId} />
            </Grid>
          )}
        </Grid>

        {shouldShowDownPaymentAndDepositSection && (
          <Box width="100%">
            {onlinePaymentsTotal === 0 ? (
              <DepositSection
                appointmentId={appointmentId}
                clientId={appointment.client}
                shouldShowAddDepositSection={!isEditAppointmentMode}
              />
            ) : (
              <OnlineBookingPaymentChip paymentTotal={onlinePaymentsTotal} />
            )}
          </Box>
        )}

        {!isEditAppointmentMode && (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            pt={2}
          >
            <Typography variant="h2">
              {DateTime.fromISO(appointment.articles[0].slots[0].start).toFormat("DDD")}
            </Typography>

            <Stack justifySelf="flex-end" alignItems="end" gap={1}>
              {appointment.createdOnline && <OnlineBookingChip source={appointment?.source} />}

              {appointment?.discountCode?.code && (
                <DiscountCodeChip code={appointment?.discountCode?.code} />
              )}
            </Stack>
          </Stack>
        )}
      </Stack>
    </>
  );
};

export default AppointmentDetailsToolbar;
